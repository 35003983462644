import React from 'react'
import { Layout } from '../components/layout/Layout'
import { HeadingMd } from '../components/heading/Heading'
import { TextBanner } from '../components/text-banner/TextBanner'

const KlachtenregelingPage = () => {
  return (
    <Layout title="Klachtenregeling" desc="">
      <TextBanner>
        <HeadingMd h={2}>Klachtenregeling</HeadingMd>
      </TextBanner>
    </Layout>
  )
}

export default KlachtenregelingPage
